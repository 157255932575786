import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';

import { OnboardEmployeeForm } from '../components/OnboardEmployee/OnboardEmployeeForm';

export const OnboardEmployee = () => {
  return (
    <ContentLayout title="Add a New Employee to Your Team" hasBackNavigation>
      <PageHeading
        title="Add a New Employee to Your Team"
        description="Fill out your new worker&rsquo;s information to start the onboarding process."
      />
      <OnboardEmployeeForm />
    </ContentLayout>
  );
};
