import dayjs from 'dayjs';
import * as z from 'zod';

import { isAfterDate, isValidCurrency } from '@/utils/validate';

import { teamFormFragmentSchema } from '../TeamFormFragment';

export type OnboardContractorSchema = z.infer<typeof schema>;

export const schema = z
  .object({
    firstName: z.string().min(1, 'Required'),
    lastName: z.string().min(1, 'Required'),
    email: z.string().min(1, 'Required').email(),
    countryCode: z.string().min(1, 'Required'),
    jobTitle: z.string().min(1, 'Required'),
    hasManager: z.string(),
    managerId: z.string().min(1, 'Required').optional(),
    servicesDescription: z
      .string()
      .min(100, 'The job description must be at least 100 characters.')
      .max(500, 'Service description should be less than 501 characters'),
    startsAt: z
      .string()
      .min(1, 'Required')
      .refine((startsAt) => dayjs(startsAt).isAfter(dayjs()), 'Start date should be after today'),
    endsAt: z.string().optional(),
    quotedCurrencyCode: z.string().min(1, 'Required'),
    quotedGrossSalary: z
      .string()
      .min(1, 'Required')
      .refine((quotedGrossSalary) => Number(quotedGrossSalary) > 0, 'Must be a valid amount'),
    grossSalaryPeriod: z.string().min(1, 'Required'),
    hasAuthority: z.boolean().refine((authority) => authority, 'Required'),
  })
  .merge(teamFormFragmentSchema)
  .refine(({ startsAt, endsAt }) => (endsAt ? isAfterDate(endsAt, startsAt) : true), {
    path: ['endsAt'],
    message: 'End date should be after start date',
  })
  .refine(
    ({ quotedGrossSalary, quotedCurrencyCode }) =>
      isValidCurrency(quotedGrossSalary, quotedCurrencyCode),
    {
      path: ['quotedGrossSalary'],
      message: 'Must be a valid amount',
    }
  );
