import { OnboardWorkerDTO } from '../../api/onboardWorker';

import { OnboardContractorSchema } from './schema';

export const onboardContractorDTO = (formData: OnboardContractorSchema): OnboardWorkerDTO => {
  return {
    worker: { firstName: formData.firstName, lastName: formData.lastName, email: formData.email },
    offer: {
      type: 'contractor',
      extraBenefits: { needsBenefits: false, newGroupBenefit: false },
      grossSalaryPeriod: formData.grossSalaryPeriod,
      jobTitle: formData.jobTitle,
      quotedCurrencyCode: formData.quotedCurrencyCode,
      quotedGrossSalary: formData.quotedGrossSalary,
      servicesDescription: formData.servicesDescription,
      countryCode: formData.countryCode,
      startsAt: formData.startsAt,
      endsAt: formData.endsAt,
      team: formData.team,
      managerId: formData.managerId,
    },
  };
};
