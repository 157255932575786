import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type Option = {
  label: string;
  value: string;
};

export type SelectFieldProps = FieldWrapperPassThroughProps & {
  options: Option[];
  className?: string;
  value?: string;
  placeholder?: string;
  registration: Partial<UseFormRegisterReturn>;
  disabled?: boolean;
};

export const SelectField = ({
  label,
  options,
  error,
  className,
  value,
  registration,
  placeholder,
  disabled,
  helperText,
}: SelectFieldProps) => (
  <FieldWrapper label={label} error={error} helperText={helperText}>
    <select
      placeholder={placeholder}
      className={clsx(
        'block w-full appearance-none',
        'rounded-3xl border border-gray-300',
        'px-3 py-2 capitalize shadow-sm',
        'focus:border-dodger-blue-600 focus:ring-dodger-blue-600',
        'truncate sm:text-sm',
        className
      )}
      disabled={disabled}
      value={value}
      translate="no"
      {...registration}
    >
      {options.map(({ label, value }) => (
        <option key={label} value={value}>
          {label}
        </option>
      ))}
    </select>
  </FieldWrapper>
);
