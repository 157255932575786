type Links = {
  first: string;
  last: string;
  prev?: string;
  next?: string;
};

type Link = {
  url?: string;
  label: string;
  active: boolean;
};

type Meta = {
  currentPage: number;
  from: number;
  lastPage: number;
  links: Link[];
  path: string;
  perPage: number;
  to: number;
  total: number;
};

export type TPaginatedQuery<T> = {
  data: T;
  links: Links;
  meta: Meta;
};

export type Data<T> = {
  data: T;
};

export type DefaultMutationResponse = {
  message: string;
};

export enum StatusColor {
  Green = 'green',
  Yellow = 'yellow',
  Red = 'red',
  Gray = 'gray',
}

export type TStatusTypes<Color = StatusColor> = {
  active: Color;
  pending: Color;
  terminated: Color;
  created: Color;
  filled: Color;
};
