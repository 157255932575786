import { AxiosResponse } from 'axios';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';

export const getWorkersXLS = (
  page?: string,
  sort?: string,
  type?: string,
  status?: string,
  location?: string
): Promise<AxiosResponse<Blob, any>> =>
  axios.get(`${API_BASE_PATH}/workers`, {
    params: {
      sort,
      page,
      'filter[type]': type,
      'filter[status]': status,
      'filter[location]': location,
    },
    headers: {
      accept: 'application/octet-stream',
    },
    responseType: 'blob',
  });
