import { PlusIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';
import { WorkersTable } from '@/features/workers';
import { Authorization, ROLES } from '@/lib/authorization';

export const TeamDashboard = () => {
  const navigate = useNavigate();
  const navigateToOnboardEmployee = () => navigate('onboard/employee');
  const navigateToOnboardContractor = () => navigate('onboard/contractor');

  return (
    <ContentLayout title="Workers">
      <PageHeading
        description="Manage your global team."
        title="Workers 👩‍💻"
        actionComponents={
          <>
            <Authorization allowedRoles={[ROLES.superAdmin, ROLES.admin]}>
              <Button
                startIcon={<PlusIcon className="h-4 w-4" />}
                size="md"
                onClick={navigateToOnboardEmployee}
              >
                Add employee
              </Button>
              <Button
                startIcon={<PlusIcon className="h-4 w-4" />}
                size="md"
                onClick={navigateToOnboardContractor}
              >
                Add contractor
              </Button>
            </Authorization>
          </>
        }
      />
      <WorkersTable />
    </ContentLayout>
  );
};
