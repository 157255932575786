import {
  CheckBoxField,
  Form,
  InputField,
  RadioField,
  SelectField,
  TextAreaField,
} from '@/components/Form';
import { FieldWrapper } from '@/components/Form/FieldWrapper';
import { CurrencySelect, NativeCountrySelect } from '@/features/countries';
import { ManagerSelect } from '@/features/users';
import { Authorization, ROLES } from '@/lib/authorization';

import { TeamFormFragment } from '../TeamFormFragment';

import { OnboardContractorSchema, schema } from './schema';

export const FORM_ID = 'onboard-contractor';

type FormProps = {
  onSubmit: (values: OnboardContractorSchema) => Promise<void>;
  submitButton: JSX.Element;
};

export const ActionForm = ({ onSubmit, submitButton }: FormProps) => {
  return (
    <Form<OnboardContractorSchema, typeof schema>
      id={FORM_ID}
      onSubmit={onSubmit}
      schema={schema}
      className="space-y-8 divide-y divide-gray-200"
      options={{
        shouldUnregister: true,
        defaultValues: {
          hasManager: 'no',
        },
      }}
    >
      {({ formState, register, watch }) => {
        const grossSalaryPeriod = watch('grossSalaryPeriod');
        const hasManager = watch('hasManager');

        return (
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-6 border-t border-gray-200 pt-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Contractor Information
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Once you complete the form, someone from the Via team will verify your new
                  worker&rsquo;s documents.
                </p>
              </div>
              <InputField
                label="First name"
                error={formState.errors['firstName']}
                registration={register('firstName')}
              />
              <InputField
                label="Last name"
                error={formState.errors['lastName']}
                registration={register('lastName')}
              />
              <InputField
                label="Title"
                error={formState.errors['jobTitle']}
                registration={register('jobTitle')}
                helperText="ex., Senior developer, Staff engineer"
              />
              <TextAreaField
                label="Description of services"
                error={formState.errors['servicesDescription']}
                registration={register('servicesDescription')}
                helperText="Please provide a 1-2 sentence overview of the worker&rsquo;s general duties and responsibilities"
              />
              <InputField
                type="email"
                label="Personal email address"
                helperText="Please use an email address that is not associated with your company (ex., john.smith@gmail.com)"
                error={formState.errors['email']}
                registration={register('email')}
              />
              <NativeCountrySelect
                label="Contractor's location"
                error={formState.errors['countryCode']}
                registration={register('countryCode')}
              />
            </div>
            <TeamFormFragment />
            <Authorization allowedRoles={[ROLES.superAdmin]}>
              <div className="space-y-6 border-t border-gray-200 pt-6">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Add Admin or Manager
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Admin and managers can approve reimbursements and time off requests, as well as
                    access contractor information.
                  </p>
                </div>
                <fieldset>
                  <legend className="text-sm font-medium text-ebony-clay-900">
                    Do you want to assign an admin or manager to your contractor?
                  </legend>
                  <div className="mt-1">
                    <div className="space-y-1">
                      <RadioField
                        id="hasManager-no"
                        label="No"
                        error={formState.errors['hasManager']}
                        registration={register('hasManager')}
                        value="no"
                        name="hasManager"
                      />
                      <RadioField
                        id="hasManager-yes"
                        label="Yes"
                        error={formState.errors['hasManager']}
                        registration={register('hasManager')}
                        value="yes"
                        name="hasManager"
                      />
                    </div>
                  </div>
                </fieldset>
                {hasManager === 'yes' && (
                  <ManagerSelect>
                    <SelectField
                      label="Manager"
                      error={formState.errors['managerId']}
                      registration={register('managerId')}
                      options={[{ label: '', value: '' }]}
                    />
                  </ManagerSelect>
                )}
              </div>
            </Authorization>

            <div className="space-y-6 border-t border-gray-200 pt-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">Contract Duration</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  You will be able to review this before signing the contract
                </p>
              </div>
              <InputField
                label="Start date"
                helperText="The contractor&rsquo;s first day of work for your company"
                type="date"
                error={formState.errors['startsAt']}
                registration={register('startsAt', { deps: ['endsAt'] })}
              />
              <InputField
                label="End date (optional)"
                helperText="If applicable, the contractor&rsquo;s last day of work for the company"
                type="date"
                error={formState.errors['endsAt']}
                registration={register('endsAt')}
              />
            </div>
            <div className="space-y-6 border-t border-gray-200 pt-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">Compensation</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  This information will also be included in the contractor&rsquo;s contract
                </p>
              </div>
              <SelectField
                label="Salary period"
                error={formState.errors['grossSalaryPeriod']}
                registration={register('grossSalaryPeriod')}
                options={[
                  { label: '', value: '' },
                  { label: 'Annual', value: 'annual' },
                  { label: 'Monthly', value: 'monthly' },
                  { label: 'Hourly', value: 'hourly' },
                ]}
              />
              <CurrencySelect>
                <SelectField
                  label="Payment currency"
                  error={formState.errors['quotedCurrencyCode']}
                  registration={register('quotedCurrencyCode', { deps: 'quotedGrossSalary' })}
                  options={[
                    { label: '', value: '' },
                    { label: 'USD', value: 'USD' },
                  ]}
                />
              </CurrencySelect>
              <InputField
                type="number"
                label={
                  <>
                    {grossSalaryPeriod && (
                      <span className="notranslate capitalize-first">{grossSalaryPeriod} </span>
                    )}
                    <span className="capitalize-first">base gross salary</span>
                  </>
                }
                error={formState.errors['quotedGrossSalary']}
                registration={register('quotedGrossSalary')}
                helperText="This is the total salary amount before taxes and deductions"
              />
            </div>
            <div className="border-t border-gray-200 pt-6">
              <FieldWrapper error={formState.errors['hasAuthority']}>
                <CheckBoxField
                  id="has-authority"
                  label={
                    <p>
                      By checking this box and clicking “
                      <span className="font-semibold text-dodger-blue-600">Submit</span>,” I confirm
                      that I have the authority to onboard new workers and issue a Statement of Work
                      on behalf of the company.
                    </p>
                  }
                  registration={register('hasAuthority')}
                />
              </FieldWrapper>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">{submitButton}</div>
            </div>
          </div>
        );
      }}
    </Form>
  );
};
