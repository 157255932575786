import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';

import { OnboardContractorForm } from '../components/OnboardContractor/OnboardContractorForm';

export const OnboardContractor = () => {
  return (
    <ContentLayout title="Add a New Contractor to Your Team" hasBackNavigation>
      <PageHeading
        title="Add a New Contractor to Your Team"
        description="Fill out your new contractor&rsquo;s information to start the onboarding process."
      />
      <OnboardContractorForm />
    </ContentLayout>
  );
};
