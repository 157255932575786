import { ArrowLeftIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';

import { Well } from '../Elements';
import { TextButton } from '../Elements/TextButton';
import { Head } from '../Head';

type ContentLayoutProps = {
  children: React.ReactNode;
  title: string;
  hasBackNavigation?: boolean;
};

const BackButton = () => {
  const navigate = useNavigate();

  if (window.history.length > 1) {
    const handleOnClick = () => {
      navigate(-1);
    };

    return (
      <TextButton
        startIcon={<ArrowLeftIcon className="text-dodger-blue-600 hover:text-dodger-blue-900" />}
        onClick={handleOnClick}
      >
        <span className="font-light">Back</span>
      </TextButton>
    );
  } else {
    const handleOnClick = () => {
      navigate('/app/team', {
        replace: true,
      });
    };

    return (
      <TextButton
        startIcon={<ArrowLeftIcon className="text-dodger-blue-600 hover:text-dodger-blue-900" />}
        onClick={handleOnClick}
      >
        <span className="font-light">Back</span>
      </TextButton>
    );
  }
};

export const ContentLayout = ({
  children,
  title,
  hasBackNavigation = false,
}: ContentLayoutProps) => (
  <>
    <Head title={title} />
    <main className="flex-1 py-6">
      <div className="mx-auto max-w-7xl sm:px-6">
        {hasBackNavigation && <BackButton />}
        <div className="space-y-6">
          <Well>
            <>{children}</>
          </Well>
        </div>
      </div>
    </main>
  </>
);
