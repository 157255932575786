import { cloneElement } from 'react';

import { SelectField, SelectFieldProps } from '@/components/Form';
import { useCountries } from '@/features/countries';

type TNativeCountrySelect = {
  error: SelectFieldProps['error'];
  label: SelectFieldProps['label'];
  registration: SelectFieldProps['registration'];
  omitCountries?: string[];
  options?: SelectFieldProps['options'];
};

export const NativeCountrySelect = ({
  label,
  error,
  options = [{ label: '', value: '' }],
  registration,
  omitCountries,
}: TNativeCountrySelect) => {
  const countriesQuery = useCountries({
    config: {
      staleTime: Infinity,
    },
  });

  const field = (
    <SelectField error={error} label={label} options={options} registration={registration} />
  );

  if (countriesQuery.isLoading)
    return cloneElement<SelectFieldProps>(field, { disabled: true, registration: {} });

  if (!countriesQuery.data) return cloneElement<SelectFieldProps>(field, { registration });

  const { data: countries } = countriesQuery.data;

  const filteredCountries = countries.filter((country) => !omitCountries?.includes(country.code));
  const sortedCountries = filteredCountries.map((country) => country).sort();

  const countryOptions: SelectFieldProps['options'] = sortedCountries.map((country) => ({
    label: country.name,
    value: country.code,
  }));

  return cloneElement<SelectFieldProps>(field, {
    options: [{ label: '', value: '' }, ...countryOptions],
    registration,
  });
};
