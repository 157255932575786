import {
  CheckBoxField,
  Form,
  InputField,
  RadioField,
  SelectField,
  TextAreaField,
} from '@/components/Form';
import { FieldWrapper } from '@/components/Form/FieldWrapper';
import { CurrencySelect, NativeCountrySelect } from '@/features/countries';
import { ManagerSelect } from '@/features/users';
import { Authorization, ROLES } from '@/lib/authorization';

import { TeamFormFragment } from '../TeamFormFragment';

import { OnboardEmployeeSchema, schema } from './schema';

type FormProps = {
  onSubmit: (values: OnboardEmployeeSchema) => Promise<void>;
  submitButton: JSX.Element;
};

export const FORM_ID = 'onboard-employee';

export const ActionForm = ({ onSubmit, submitButton }: FormProps) => {
  return (
    <Form<OnboardEmployeeSchema, typeof schema>
      id={FORM_ID}
      onSubmit={onSubmit}
      schema={schema}
      className="space-y-8 divide-y divide-ebony-clay-200"
      options={{
        shouldUnregister: true,
        defaultValues: {
          needsBenefits: 'no',
          newGroupBenefit: 'no',
          hasManager: 'no',
        },
      }}
    >
      {({ register, formState, watch }) => {
        const countryCode = watch('countryCode');
        const needsBenefits = watch('needsBenefits');
        const newGroupBenefit = watch('newGroupBenefit');
        const grossSalaryPeriod = watch('grossSalaryPeriod');
        const hasManager = watch('hasManager');

        return (
          <div className="space-y-8 divide-y divide-ebony-clay-200">
            <div className="space-y-6 border-t border-ebony-clay-200 pt-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-ebony-clay-900">
                  Employee Information
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-ebony-clay-500">
                  Once you complete the form, someone from the Via team will verify your new
                  worker&rsquo;s documents.
                </p>
              </div>
              <InputField
                label="First name"
                error={formState.errors['firstName']}
                registration={register('firstName')}
              />
              <InputField
                label="Last name"
                error={formState.errors['lastName']}
                registration={register('lastName')}
              />
              <InputField
                label="Job title"
                error={formState.errors['jobTitle']}
                registration={register('jobTitle')}
                helperText="ex., Senior developer, Staff engineer"
              />
              <TextAreaField
                label="Description of services"
                error={formState.errors['servicesDescription']}
                registration={register('servicesDescription')}
                helperText="Please provide a 1-2 sentence overview of the worker&rsquo;s general duties and responsibilities"
              />
              <InputField
                type="email"
                label="Personal email address"
                helperText="Please use an email address that is not associated with your company (ex., john.smith@gmail.com)"
                error={formState.errors['email']}
                registration={register('email')}
              />
              <NativeCountrySelect
                label="Employment location"
                error={formState.errors['countryCode']}
                registration={register('countryCode')}
              />
            </div>
            <TeamFormFragment />
            <Authorization allowedRoles={[ROLES.superAdmin]}>
              <div className="space-y-6 border-t border-gray-200 pt-6">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Add Admin or Manager
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Admin and managers can approve reimbursements and time off requests, as well as
                    access employee information.
                  </p>
                </div>
                <fieldset>
                  <legend className="text-sm font-medium text-ebony-clay-900">
                    Do you want to assign an admin or manager to your employee?
                  </legend>
                  <div className="mt-1">
                    <div className="space-y-1">
                      <RadioField
                        id="hasManager-no"
                        label="No"
                        error={formState.errors['hasManager']}
                        registration={register('hasManager')}
                        value="no"
                        name="hasManager"
                      />
                      <RadioField
                        id="hasManager-yes"
                        label="Yes"
                        error={formState.errors['hasManager']}
                        registration={register('hasManager')}
                        value="yes"
                        name="hasManager"
                      />
                    </div>
                  </div>
                </fieldset>
                {hasManager === 'yes' && (
                  <ManagerSelect>
                    <SelectField
                      label="Manager"
                      error={formState.errors['managerId']}
                      registration={register('managerId')}
                      options={[{ label: '', value: '' }]}
                    />
                  </ManagerSelect>
                )}
              </div>
            </Authorization>
            <div className="space-y-6 border-t border-ebony-clay-200 pt-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-ebony-clay-900">
                  Contract Duration
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-ebony-clay-500">
                  You will be able to review this before signing the contract
                </p>
              </div>
              <InputField
                label="Start date"
                helperText="The employee&rsquo;s first day of work for your company"
                type="date"
                error={formState.errors['startsAt']}
                registration={register('startsAt', { deps: ['endsAt'] })}
              />
              <InputField
                label="End date (optional)"
                helperText="If applicable, the employee&rsquo;s last day of work for the company"
                type="date"
                error={formState.errors['endsAt']}
                registration={register('endsAt')}
              />
            </div>

            <div className="space-y-6 border-t border-ebony-clay-200 pt-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-ebony-clay-900">
                  Additional Benefits
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-ebony-clay-500">
                  Via will automatically register your new workers for benefits that are required by
                  law, no form necessary. To offer your workers add-on benefits, such as private
                  health insurance or a grocery allowance, click{' '}
                  <span className="font-semibold text-dodger-blue-600">Yes</span> and choose an
                  existing benefits package or create a new one.
                </p>
              </div>
              <fieldset>
                <legend className="text-sm font-medium text-ebony-clay-900">
                  Would you like to include any additional benefits for this employee?
                </legend>
                <div className="mt-1">
                  <div className="space-y-1">
                    <RadioField
                      id="needsBenefits-no"
                      label="No"
                      error={formState.errors['needsBenefits']}
                      registration={register('needsBenefits')}
                      value="no"
                      name="needsBenefits"
                    />
                    <RadioField
                      id="needsBenefits-yes"
                      label="Yes"
                      error={formState.errors['needsBenefits']}
                      registration={register('needsBenefits')}
                      value="yes"
                      name="needsBenefits"
                    />
                  </div>
                </div>
              </fieldset>
              {needsBenefits === 'yes' && (
                <div className="space-y-6">
                  <fieldset>
                    <legend className="text-sm font-medium text-ebony-clay-900">
                      Choose a plan
                    </legend>
                    <div className="mt-1">
                      <div className="space-y-1">
                        <RadioField
                          id="existing-group-benefit"
                          label="Existing group benefit"
                          description="This will include the employee in the existing benefits plan of your company"
                          error={formState.errors['newGroupBenefit']}
                          registration={register('newGroupBenefit')}
                          value="no"
                          name="newGroupBenefit"
                        />
                        <RadioField
                          id="new-benefit-plan"
                          label="New benefit plan"
                          description="Create a new benefits plan for the employee"
                          error={formState.errors['newGroupBenefit']}
                          registration={register('newGroupBenefit')}
                          value="yes"
                          name="newGroupBenefit"
                        />
                      </div>
                    </div>
                  </fieldset>

                  {newGroupBenefit === 'yes' && (
                    <div className="space-y-6 border-t border-ebony-clay-200 pt-6">
                      <div>
                        <h3 className="text-lg font-medium leading-6 text-ebony-clay-900">
                          New plan details
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-ebony-clay-500">
                          Please select what you wish to include in the new benefits plan.
                          Via&rsquo;s team will contact you to follow up on the details of the new
                          plan
                        </p>
                      </div>
                      <div className="space-y-1">
                        <CheckBoxField
                          id="dependentLife"
                          label="Life Insurance"
                          error={formState.errors['dependentLife']}
                          registration={register('dependentLife')}
                        />
                        <CheckBoxField
                          id="health"
                          label="Health Insurance"
                          error={formState.errors['health']}
                          registration={register('health')}
                        />
                        <CheckBoxField
                          id="dental"
                          label="Dental Insurance"
                          error={formState.errors['dental']}
                          registration={register('dental')}
                        />
                        <CheckBoxField
                          id="groceryAllowance"
                          label="Grocery Allowance"
                          error={formState.errors['groceryAllowance']}
                          registration={register('groceryAllowance')}
                        />
                        <CheckBoxField
                          id="savingsFund"
                          label="Savings Fund"
                          error={formState.errors['savingsFund']}
                          registration={register('savingsFund')}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="space-y-6 border-t border-ebony-clay-200 pt-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-ebony-clay-900">Compensation</h3>
                <p className="mt-1 max-w-2xl text-sm text-ebony-clay-500">
                  This information will also be included in the employee&rsquo;s contract
                </p>
              </div>
              <SelectField
                label="Salary period"
                error={formState.errors['grossSalaryPeriod']}
                registration={register('grossSalaryPeriod')}
                options={[
                  { label: '', value: '' },
                  { label: 'Annual', value: 'annual' },
                  { label: 'Monthly', value: 'monthly' },
                  { label: 'Hourly', value: 'hourly' },
                ]}
              />
              <CurrencySelect>
                <SelectField
                  label="Payment currency"
                  error={formState.errors['quotedCurrencyCode']}
                  registration={register('quotedCurrencyCode', { deps: 'quotedGrossSalary' })}
                  options={[
                    { label: '', value: '' },
                    { label: 'USD', value: 'USD' },
                  ]}
                />
              </CurrencySelect>
              <InputField
                type="number"
                label={
                  <>
                    {grossSalaryPeriod && (
                      <span className="notranslate capitalize-first">{grossSalaryPeriod} </span>
                    )}
                    <span className="capitalize-first">base gross salary</span>
                  </>
                }
                error={formState.errors['quotedGrossSalary']}
                registration={register('quotedGrossSalary')}
                helperText="This is the total salary amount before taxes and deductions "
              />
            </div>
            {['MX', 'CA', 'CO'].includes(countryCode) && (
              <div className="space-y-6 border-t border-ebony-clay-200 pt-6">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-ebony-clay-900">
                    Statutory Benefits
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-ebony-clay-500">
                    This will be included in the employee contract
                  </p>
                </div>
                {countryCode === 'MX' && (
                  <>
                    <SelectField
                      label="Christmas bonus / Aguinaldo"
                      error={formState.errors['bonusDays']}
                      registration={register('bonusDays')}
                      options={[
                        { label: '', value: '' },
                        { label: '15 Days', value: '15' },
                        { label: '20 Days', value: '20' },
                        { label: '25 Days', value: '25' },
                        { label: '30 Days', value: '30' },
                      ]}
                    />
                    <InputField
                      label="Vacation days"
                      error={formState.errors['vacationDays']}
                      registration={register('vacationDays')}
                    />
                    <SelectField
                      label="Vacation premium"
                      error={formState.errors['vacationsPremiumPercentage']}
                      registration={register('vacationsPremiumPercentage')}
                      options={[
                        { label: '', value: '' },
                        { label: '25%', value: '25' },
                        { label: '50%', value: '50' },
                        { label: '75%', value: '75' },
                      ]}
                    />
                  </>
                )}
                {countryCode === 'CA' && (
                  <SelectField
                    label="PTO"
                    error={formState.errors['pto']}
                    registration={register('pto')}
                    options={[
                      { label: '', value: '' },
                      { label: 'Statutory', value: 'statutory' },
                      { label: 'Flexible', value: 'flexible' },
                    ]}
                  />
                )}
                {countryCode === 'CO' && (
                  <InputField
                    label="Vacation days"
                    error={formState.errors['vacationDays']}
                    registration={register('vacationDays')}
                  />
                )}
              </div>
            )}
            <div className="border-t border-ebony-clay-200 pt-6">
              <FieldWrapper error={formState.errors['hasAuthority']}>
                <CheckBoxField
                  id="has-authority"
                  label={
                    <p>
                      By checking this box and clicking “
                      <span className="font-semibold text-dodger-blue-600">Submit</span>,” I confirm
                      that I have the authority to onboard new workers and issue a Statement of Work
                      on behalf of the company.
                    </p>
                  }
                  registration={register('hasAuthority')}
                />
              </FieldWrapper>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">{submitButton}</div>
            </div>
          </div>
        );
      }}
    </Form>
  );
};
